@media only screen and (min-width: 0px) and (max-width: 3300px) {
    .image-gallery {
        width: 100% !important;
        height: 100%;
    }
}
/*@media only screen and (min-width: 451px) and (max-width: 3000px) {*/
/*    .image-gallery {*/
/*        width: 40%;*/
/*        height: auto;*/
/*    }*/
/*}*/

.image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
}
